<template>
  <div class="plans h-100 d-flex flex-column justify-content-between">
    <div class="page py-0 py-sm-5">
      <div class="container">
        <div class="page-container">
          <h2 class="page__title text-center">
            Get 3-Day Trial - only
            <span class="text-highlighted">
        $0.99
      </span>
          </h2>
          <div class="get-list">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-9">
                <h3 class="get-list__title text-highlighted text-center">
                  What do you get:
                </h3>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <p class="get-list__item">
                      1 detailed step-by-step solution to
                      a problem of any complexity
                    </p>
                  </div>
                  <div class="col-md-6 col-12">
                    <p class="get-list__item">
                      A fast and timely solution according
                      to your individual requirements
                    </p>
                  </div>
                  <div class="col-md-6 col-12">
                    <p class="get-list__item">
                      Secure private chat with an expert
                      assigned to you
                    </p>
                  </div>
                  <div class="col-md-6 col-12">
                    <p class="get-list__item">
                      Ability to upload a photo with a task
                      or write it yourself
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-continue d-flex justify-content-center mb-4">
            <button type="button" class="btn page-continue__btn w-100" @click="payFormClick()">
              Continue
            </button>
          </div>
          <div class="page-subscriptions d-flex align-items-center flex-lg-row flex-column"
               v-if="$store.state.cloaking === 'true'"
          >
            <div class="subscription w-100"
                 v-for="plan of subscriptions"
                 :key="plan.type"
                 :class="{
                   'subscription_chosen': plan.type === chosenSubscription.type,
                   'order-0': plan.type === '1 Month',
                   'order-lg-1': plan.type === '1 Month',
                   'order-1': plan.type === '1 Year',
                   'order-lg-0': plan.type === '1 Year',
                   'order-2': plan.type === '6 Month'
                 }"
                 @click="chooseSubscription(plan)"
            >
              <div class="subscription-trial">
                <p class="subscription-trial__title">
                  Get 3-Day Trial for
                </p>
                <p class="subscription-trial__price">
                  $0.99
                  <span class="subscription-trial__price_solution">
              / 1 Solution
            </span>
                </p>
              </div>
              <div class="subscription-plan">
                <div class="subscription-after d-flex align-items-center">
                  <svg class="subscription-after__mark" width="24" height="24" viewBox="0 0 24 24"
                       fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071
                          9.29289C16.0676 9.65338 16.0953 10.2206 15.7903 10.6129L15.7071
                          10.7071L11.7071 14.7071C11.3466 15.0676 10.7794 15.0953 10.3871
                          14.7903L10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834
                          8.29289 11.2929C8.65338 10.9324 9.22061 10.9047 9.6129 11.2097L9.70711
                          11.2929L11 12.585L14.2929 9.29289Z"
                          fill="#5E5CE6"/>
                    <g opacity="0.5">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22
                            22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 4C16.4183 4 20 7.58172 20
                            12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172
                            7.58172 4 12 4Z"
                            fill="#5E5CE6"/>
                    </g>
                  </svg>
                  <p class="subscription-after__text m-0">
                    Subscription plan after trial:
                  </p>
                </div>
                <p class="subscription-plan__weekly m-0">
                  only ${{ plan.perWeek }}
                  <span class="subscription-plan__weekly_small">
              / per week
            </span>
                </p>
                <p class="subscription-plan__full-price">
                  {{ plan.type }} - ${{ plan.totalPrice }}
                </p>
                <p class="subscription-plan__text">
                  $0.99 charged today. After your introductory period, you will be automatically
                  charged {{ plan.billed }}. Cancel anytime.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="safe text-center">
      <div class="container">
        <div class="safe-container">
          <p class="safe__text">
            By continuing you indicate that you've read and agree to our
            <router-link to="/terms">
              Terms & Conditions
            </router-link>,
            <router-link to="/privacy">
              Privacy Policy
            </router-link>,
            <router-link to="/money-back">
              Money Back
            </router-link> and
            <router-link to="/sub-terms">
              Subscription Terms
            </router-link>
          </p>
          <p class="safe__guaranteed">
            GUARANTEED
            <span class="safe__guaranteed_highlighted">
          SAFE
        </span>
            CHECKOUT
          </p>
          <div class="safe-labels">
            <img src="../assets/visa.svg" alt="">
            <img src="../assets/mastercard.svg" alt="">
            <img src="../assets/american-express.svg" alt="">
            <img src="../assets/diners-club.svg" alt="">
            <img src="../assets/discover.svg" alt="">
            <img src="../assets/paypal.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="trust">
      <div class="container d-flex d-md-block flex-column align-items-center">
        <div class="d-flex align-items-md-center flex-column flex-md-row">
          <div class="trust-block d-flex align-items-center flex-grow-1">
            <img class="trust__icon" src="../assets/return.svg" alt="">
            <div class="">
              <p class="trust__title">Return within 90 Days</p>
              <p class="trust__small mb-0">if we are unable to solve your problem.</p>
            </div>
          </div>
          <div class="trust-block d-flex align-items-center flex-grow-1">
            <img class="trust__icon" src="../assets/secure.svg" alt="">
            <div class="">
              <p class="trust__title">Secure Payment</p>
              <p class="trust__small mb-0">100% secure payment</p>
            </div>
          </div>
          <div class="trust-block d-flex align-items-center flex-grow-1">
            <img class="trust__icon" src="../assets/support.svg" alt="">
            <div class="">
              <p class="trust__title">24/7 Support</p>
              <p class="trust__small mb-0">Dedicated experts available at all times</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <billing-modal :is-modal-show="isBillingModalShow"
                   :product="chosenSubscription"
                   @onstatechange="onBillingModalClose"
    ></billing-modal>
  </div>
</template>

<script>
import BillingModal from '../components/BillingModal.vue';

export default {
  name: 'Plans',
  components: {
    BillingModal,
  },
  data() {
    return {
      isBillingModalShow: false,
      chosenSubscription: {
        type: '1 Month',
        totalPrice: '29.99',
        perWeek: '7.49',
        billed: '$29.99 every month',
        productId: '77aaebd8-8396-41ff-a70d-964e583e397d',
      },
      subscriptions: [
        {
          type: '1 Month',
          totalPrice: '29.99',
          perWeek: '7.49',
          billed: '$29.99 every month',
          productId: '77aaebd8-8396-41ff-a70d-964e583e397d',
        },
        {
          type: '6 Month',
          totalPrice: '69.99',
          perWeek: '2.99',
          billed: '$69.99 every 6 months',
          productId: 'a33b35fd-b317-489e-88a1-10466b0c8bef',
        },
        {
          type: '1 Year',
          totalPrice: '99.99',
          perWeek: '1.99',
          billed: '$99.99 every year',
          productId: '2a678d97-653b-41d5-8a41-f6e62fe77c44',
        },
      ],
    };
  },
  methods: {
    chooseSubscription(subscription) {
      this.chosenSubscription = subscription;
    },
    payFormClick() {
      this.isBillingModalShow = true;
      window.fbq('track', 'InitiateCheckout');
      this.$store.dispatch('clickHouseEvent', 'InitiateCheckout');
    },
    onBillingModalClose() {
      this.isBillingModalShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-image: url('../assets/Image.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  * {
    font-family: 'DM Sans', sans-serif;
  }

  &-container {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(24px);
    border-radius: 32px;
    padding: 40px;
  }

  &__title {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -2px;
    color: #12022F;
    margin-bottom: 24px;
  }

  &-continue {
    &__btn {
      background: #5E5CE6;
      color: white;
      border-radius: 28px;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.36px;
      padding-top: 16px;
      padding-bottom: 16px;
      width: 100%;
      max-width: 312px;
      text-transform: uppercase;

      &:hover {
        background: rgba(94, 92, 230, 0.85);
      }
    }
  }
}

.get-list {
  margin-bottom: 24px;

  &__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.4px;
    margin-bottom: 16px;
  }

  &__item {
    margin-bottom: 16px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #594D6D;
    padding-left: 48px;
    position: relative;

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 4px;
      background-image: url('../assets/check.svg');
    }
  }
}

.subscription {
  padding: 24px;
  background: #FFFFFF;
  border-radius: 32px;
  transition-duration: .3s;
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;

  * {
    transition-duration: .3s;
  }

  &-trial {
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 32px;

    &__title {
      font-weight: bold;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: -0.4px;
      margin-bottom: 24px;
      color: #1F1D2B;
    }

    &__price {
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: -2px;
      color: #1F1D2B;

      &_solution {
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.4px;
        mix-blend-mode: normal;
        opacity: 0.5;
        margin: 0;
      }
    }
  }

  &-plan {
    &__weekly {
      font-weight: bold;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: -0.4px;
      color: #1F1D2B;

      &_small {
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.4px;
      }
    }

    &__full-price {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.4px;
      color: #1F1D2B;
      opacity: 0.5;
    }

    &__text {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: -0.4px;
      color: #1F1D2B;
      mix-blend-mode: normal;
      opacity: 0.5;
    }
  }

  &-after {
    margin-bottom: 16px;

    &__mark {
      margin-right: 4px;

      path {
        fill: #5E5CE6;
      }
    }

    &__text {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.4px;
      color: #1F1D2B;
      mix-blend-mode: normal;
      opacity: 0.8;
    }
  }

  &_chosen {
    background: #5E5CE6;

    .subscription {
      &-trial {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        &__title {
          color: #FFFFFF;
        }

        &__price {
          color: #FFFFFF;

          &_solution {
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }

      &-after {
        &__mark {
          path {
            fill: #FFFFFF;
          }
        }

        &__text {
          color: rgba(255, 255, 255, 0.8);
        }
      }

      &-plan {
        &__full-price {
          color: #FFFFFF;
        }

        &__weekly {
          color: #FFFFFF;

          &_small {
            color: rgba(255, 255, 255, 0.5);
          }
        }

        &__text {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}

.safe {
  padding: 64px 0 40px;

  * {
    font-family: 'DM Sans', sans-serif;
  }

  &__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.355556px;
    color: #64646C;
    margin-bottom: 48px;

    a {
      color: #5E5CE6;
      text-decoration: none;
    }
  }

  &__guaranteed {
    font-weight: 600;
    font-size: 21.8296px;
    line-height: 140%;
    letter-spacing: 0.642047px;
    color: #B1B1B5;
    margin-bottom: 16px;

    &_highlighted {
      color: #5E5CE6;
    }
  }

  &-labels {
    img {
      margin: 0 4px;
    }
  }
}

.trust {
  padding: 80px 0;
  background: #5E5CE6;

  * {
    font-family: 'DM Sans', sans-serif;
  }

  &__icon {
    margin-right: 24px;
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.8px;
    color: #FFFFFF;
    margin-bottom: 8px;
  }

  &__small {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.355556px;
    color: rgba(255, 255, 255, 0.5);
  }
}

.text-highlighted {
  color: #5E5CE6;
}

@media screen and (max-width: 992px) {
  .subscription {
    margin: 4px 0;
    background: #F3F4F8;

    &_chosen {
      background: #5E5CE6;
    }
  }
}

@media screen and (max-width: 576px) {
  .header {
    &-logo {
      position: unset;

      &__text {
        display: none;
      }
    }
  }

  .page {
    background-image: none;

    &-container {
      background: rgba(255, 255, 255, 1);
      border-radius: 0;
      padding: 0;
    }

    &__title {
      font-size: 28px;
      line-height: 56px;
      margin-bottom: 8px;
    }
  }
  .get-list {
    &__title {
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 24px;
    }

    &__item {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .safe {
    padding-top: 40px;

    &__guaranteed {
      font-size: 20px;
    }

    &__text {
      font-size: 14px;
    }
  }

  .trust {
    padding: 40px 0;

    &-block {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
