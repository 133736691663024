<template>
  <b-modal ref="hold-on-modal"
           v-model="modalState"
           hide-footer
           @show="initForm">
    <div class="d-block position-relative">
      <div class="modal-head d-flex align-items-center justify-content-between">
        <p class="m-0">Total</p>
        <p class="m-0">0.99$</p>
      </div>
      <div id="solid-payment-form">
        <div class="modal-pay-buttons d-flex flex-column align-items-stretch">
          <div id="apple-pay-button-container"></div>
          <div id="paypal-button"></div>
          <p class="modal-pay-button__delimiter">or</p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import * as Processing from 'processing';
import { setCookie } from '@/main';

export default {
  name: 'BillingModal',
  props: [
    'isModalShow',
    'product',
  ],
  data() {
    return {
      formLoaded: false,
    };
  },
  computed: {
    modalState: {
      get() {
        return this.isModalShow;
      },
      set(value) {
        this.$emit('onstatechange', value);
        this.formLoaded = false;
      },
    },
  },
  methods: {
    onSuccess() {
      window.fbq('track', 'Purchase');
      this.$store.dispatch('clickHouseEvent', 'Purchase')
        .then(() => Processing.Processing.getCredentials())
        .then((resp) => {
          this.$store.commit('setCredentials', resp.data);
          setCookie('appLink', resp.data.deeplink);
          this.$router.push('/success');
        })
        .catch((error) => {
          console.log('ERROR', error);
          this.$router.push('/success');
        });
    },
    initForm() {
      Processing.Processing.initForm(
        this.product.productId,
        document.querySelector('#eventId')
          .innerText
          .trim()
          .includes('{{')
          ? '23239767'
          : document.querySelector('#eventId')
            .innerText
            .trim(),
        'solid-payment-form',
        {
          formParams: {
            submitButtonText: 'Pay',
            isCardHolderVisible: false,
            headerText: 'Enter your debit or credit card details (from merchant)',
            titleText: '',
            formTypeClass: 'flat',
            isSolidLogoVisible: false,
            googleFontLink: '//fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
            cardBrands: ['visa', 'mastercard', 'maestro', 'discover'],
            'font-family': 'Roboto',
          },
          styles: {
            form_body: {
              'min-width': 'unset',
            },
          },
          applePayButtonParams: {
            enabled: true,
            containerId: 'apple-button',
            color: 'white',
          },
          paypalContainerId: 'paypal-button',
        },
        {
          onMounted: this.onMounted,
          onSuccess: this.onSuccess,
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &.show {
    display: block;
  }

  &-head {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #5B5B5B;
    margin-bottom: 24px;
  }

  &-loading-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    z-index: 101;
  }

  &-pay-button {
    &__delimiter {
      font-size: 17px;
      line-height: 25px;
      color: #ACB2C0;
      text-align: center;
      margin: 0;
      position: relative;
      padding: 32px 0;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 40%;
        height: 1px;
        background: #9F9F9F;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 40%;
        height: 1px;
        background: #9F9F9F;
      }
    }
  }
}
</style>
